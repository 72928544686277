body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.full {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.menu {
  background-color: black;
  z-index: 1;
  transition: all 0.8s ease-out;
}

.menu.hide {
  clip-path: circle(0.0001% at 100% 0);
}
.menu.show {
  clip-path: circle(150% at 100% 0);
}
.mainScreen {
  background-color: white;
}

.menuIcon {
  position: absolute;
  top: 30px;
  right: 30px;
  transition: transform 0.15s cubic-bezier(0.27, 0.76, 0.49, 1.14);
}

.open:hover {
  transform: scaleY(0.8);
}

.close {
  color: white;
}

.close:hover {
  transform: rotateZ(90deg);
}
/* 92 302 8696444 */
